import client from '../../libs/client';

const URL_BASE = '/energy-allocation';

export class EnergyAllocationGateway {
    getAllocationAlgorithmConfig = async (operationId) => {
        const { data } = await client.get(
            `${URL_BASE}/config/last?operationId=${operationId}`
        );
        return data;
    };

    getParticipantsParameters = async (configId) => {
        // const response = [];
        // const chunkSize = 50;
        // const participantsIds = [...consumersIds, ...producersIds];
        // for (let i = 0; i < participantsIds.length; i += chunkSize) {
        //     const chunk = participantsIds.slice(i, i + chunkSize);
        //     const participantsIdsQuery = chunk
        //         .map((id) => `participantsIds=${id}`)
        //         .join('&');
        //
        //     response.push(...data);
        // }
        const { data } = await client.get(
            `${URL_BASE}/active-config-param?allocationAlgorithmId=${configId}`
        );
        return this._fromObject(data);
    };

    _fromObject(dataArray) {
        const result = {};

        dataArray.forEach((item) => {
            const { consumerId, producerId, value } = item;

            if (producerId) {
                if (!result[producerId]) {
                    result[producerId] = {};
                }
                result[producerId][consumerId] = value;
            } else {
                result[consumerId] = value;
            }
        });

        return result;
    }

    /**
     * Due to compatibility issues :
     * Store format :
     * {
     *     <consumerId>: <value>
     * }
     * or
     * {
     *     <consumerId>: {
     *         <producerId1>: <value1>,
     *         <producerId2>: <value2>
     *     }
     * }
     *
     * Enopower needed format :
     * [
     *     {"consumerId": <consumerId>, "value": <value>}
     * ]
     * or
     * [
     *     {
     *         "consumerId": <consumerId>,
     *         "producerId1": <producerId1>,
     *         "value": <value1>
     *     },
     *     {
     *         "consumerId": <consumerId>,
     *         "producerId2": <producerId2>,
     *         "valu": <value2>
     *     }
     * ]
     */
    _serializeParticipantsParameters = (input) => {
        const newParticipantsParametersEntries = Object.entries(input);
        return newParticipantsParametersEntries.reduce(
            (acc, [consumerId, value]) => {
                if (typeof value === 'object') {
                    const valueEntries = Object.entries(value);
                    valueEntries.forEach(([producerId, value]) => {
                        acc.push({
                            consumerId: Number(producerId), // TODO do better than this mixup names
                            producerId: Number(consumerId), // TODO do better  than this mixup names
                            value: Number(value),
                        });
                    });
                } else {
                    acc.push({
                        consumerId: Number(consumerId),
                        producerId: undefined,
                        value: Number(value),
                    });
                }
                return acc;
            },
            []
        );
    };

    createConfig = async (operationId, type, params) => {
        const serializedParams = this._serializeParticipantsParameters(params);
        const { data } = await client.post(
            `${URL_BASE}/config?operationId=${operationId}`,
            {
                type: type,
                params: serializedParams,
            }
        );
        return data;
    };

    createParticipantParameter = async (
        consumerId,
        producerId,
        configId,
        value
    ) => {
        const { data } = await client.post(
            `${URL_BASE}/config-param?consumerId=${consumerId}&producerId=${producerId}`,
            {
                allocationAlgorithmId: configId,
                value,
            }
        );
        return data;
    };

    getOperationSeasonality = async (operationId) => {
        const { data } = await client.get(
            `${URL_BASE}/seasonality?operationId=${operationId}`
        );
        return data.seasonality;
    };

    createOperationSeasonality = async (operationId, seasonality) => {
        const { data } = await client.post(
            `${URL_BASE}/seasonality?operationId=${operationId}`,
            seasonality
        );
        return data;
    };
}
