import {
    SET_NEW_ALLOCATION_ALGORITHM_TYPE,
    SET_NEW_PARTICIPANT_PARAMETER,
    SET_NEW_PARTICIPANTS_PARAMETERS,
    SET_PERSISTED_ALLOCATION_ALGORITHM_CONFIG_TYPE,
    SET_PERSISTED_PARTICIPANTS_PARAMETERS,
    SET_PERSISTED_SEASONALITY,
} from './constants';
import { combineReducers } from 'redux';

const persistedDataInitialState = {
    allocationAlgorithmConfig: null,
    participantsParameters: {},
};

const persistedDataReducer = (state = persistedDataInitialState, action) => {
    switch (action.type) {
        case SET_PERSISTED_ALLOCATION_ALGORITHM_CONFIG_TYPE: {
            return { ...state, allocationAlgorithmConfig: action.config };
        }
        case SET_PERSISTED_PARTICIPANTS_PARAMETERS: {
            const { parameters } = action;
            // const mapConsumerIdToParameter = parameters.reduce(
            //     (acc, parameter) => {
            //         if (parameter.producerId) {
            //             acc[parameter.consumerId][parameter.producerId] =
            //                 parameter.value;
            //             return acc;
            //         } else {
            //             acc[parameter.consumerId] = parameter.value;
            //             return acc;
            //         }
            //     },
            //     {}
            // );

            return {
                ...state,
                participantsParameters: {
                    ...parameters,
                },
            };
        }
        case SET_PERSISTED_SEASONALITY: {
            return { ...state, operationSeasonality: action.seasonality };
        }
        default:
            return state;
    }
};

const newDataInitialState = {
    allocationAlgorithmType: null,
    participantsParameters: {},
};

const newDataReducer = (state = newDataInitialState, action) => {
    switch (action.type) {
        case SET_NEW_ALLOCATION_ALGORITHM_TYPE: {
            return { ...state, allocationAlgorithmType: action.algorithmType };
        }
        case SET_NEW_PARTICIPANTS_PARAMETERS: {
            const { parameters } = action;
            return {
                ...state,
                participantsParameters: {
                    ...parameters,
                },
            };
        }
        case SET_NEW_PARTICIPANT_PARAMETER: {
            const { consumerId, producerId, value } = action;

            // Crée une copie de l'état actuel
            const newState = {
                ...state,
                participantsParameters: {
                    ...state.participantsParameters,
                },
            };

            const participantsParameters = newState.participantsParameters;

            if (producerId) {
                if (!participantsParameters[producerId]) {
                    participantsParameters[producerId] = {};
                }

                participantsParameters[producerId] = {
                    ...participantsParameters[producerId],
                    [consumerId]: value,
                };
            } else {
                participantsParameters[consumerId] = value;
            }

            return newState;
        }
        default:
            return state;
    }
};

const energyAllocationReducer = combineReducers({
    persistedData: persistedDataReducer,
    newData: newDataReducer,
});

export { energyAllocationReducer };
